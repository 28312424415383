<template>
  <div>
    <v-simple-table height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr v-if="errTexts.length > 0" style="font-size: 12px">
            <td colspan="13" role="columnheader">
              <div class="yellow error--text pa-3">
                <ul>
                  <li
                    v-for="(txt, ti) in errTexts"
                    :key="`txterr_${ti}`"
                    v-html="txt"
                  ></li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <th role="columnheader" class="text-center vertical-align-middle">
              #
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.customer_order_id") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.receiver") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.phone_number") }}
            </th>
            <template v-if="idEMarket !== -1">
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.city") }}
              </th>
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.district") }}
              </th>
              <th role="columnheader" class="text-center vertical-align-middle">
                {{ $t("labels.ward") }}
              </th>
            </template>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.address") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.cash_on_delivery") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.note") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.product") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.delivery_company") }}
            </th>
            <th role="columnheader" class="text-center vertical-align-middle">
              {{ $t("labels.delivery_order_id") }}
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in items"
            :key="item.id"
            class="text-center"
            :class="{ 'error--text': errRows.includes(item.tt) }"
          >
            <td>{{ item.tt }}</td>
            <td>{{ item.customer_order_id }}</td>
            <td>{{ replaceTxtStar(item.receiver_name) }}</td>
            <td>{{ replaceTxtStar(item.receiver_phone_number) }}</td>
            <template v-if="idEMarket !== -1">
              <td>{{ item.city_name }}</td>
              <td>{{ item.county_name }}</td>
              <td>{{ item.ward_name }}</td>
            </template>
            <td>{{ replaceTxtStar(item.address) }}</td>
            <td>{{ formatNumber(item.cash_on_delivery) }}</td>
            <td>{{ item.note }}</td>
            <td>
              <div
                v-for="(goods, gi) in item.goods_items || []"
                :key="`${index}_${gi}_${goods.barcode}`"
              >
                {{ goods.barcode }} x
                {{ goods.quantity }}
              </div>
            </td>
            <td>{{ item.delivery_company }}</td>
            <td>{{ item.delivery_order_id }}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="8">
        <div class="d-flex justify-start align-center">
          <div class="mr-2" style="max-width: 120px">
            <select-warehouse-model
              v-model="idWarehouse"
              :single-line="false"
              :label="$t('labels.warehouse_1')"
              :auto-select="true"
            ></select-warehouse-model>
          </div>

          <div class="mr-2" style="max-width: 120px">
            <v-autocomplete
              v-model="idEMarket"
              :items="sourceOptions"
              :label="$t('labels.e_market')"
              :placeholder="$t('labels.e_market')"
              :disabled="false"
              class="c-input-small"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </div>

          <div class="mr-2" style="max-width: 200px">
            <v-autocomplete
              v-model="idEMarketShop"
              :items="shopOptions"
              :label="$t('labels.shop')"
              :placeholder="$t('labels.shop')"
              :disabled="false"
              class="c-input-small"
              dense
              outlined
              hide-details
            ></v-autocomplete>
          </div>

          <!-- <SelectEMarket
            style="max-width: 180px"
            name="id_e_market"
            class="mr-2 c-input-small"
            :label="$t('labels.e_market')"
            @onFilter="onFilterChange"
          />
          <SelectEMarketShop
            style="max-width: 200px"
            name="id_e_market_shop"
            :filters="filters"
            class="mr-2 c-input-small"
            :label="$t('labels.shop')"
            @onFilter="onFilterChange"
            @itemIdWarehouse="onWarehouseChange"
            @itemEMarketWarehouseName="onEMarketWarehouseChange"
          /> -->
          <a
            class="mr-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default default"
            href="/files/3.1.Template-Giu-Ton.xlsx"
            target="_blank"
            v-if="idEMarket === -1"
          >
            <v-icon>mdi-download</v-icon>
            {{ $t("labels.sample_file") }}
          </a>
          <v-btn
            color="info"
            @click="$refs.inputUploadFile.click()"
            :disabled="!idEMarketShop"
            class="mr-2"
          >
            <v-icon>mdi-upload</v-icon>
            {{ $t("labels.upload") }}
          </v-btn>
          <input
            type="file"
            ref="inputUploadFile"
            accept=".xlsx"
            @change="onInputFileChange"
            class="d-none"
          />

          <div style="max-width: 200px">
            <v-checkbox
              hide-details
              class="c-input-small c-input-black mt-0 pt-0"
              v-model="auto_resolve_order"
              :value="1"
              :label="$t('labels.auto_skip_existing_order')"
            ></v-checkbox>
          </div>
        </div>
      </v-col>
      <v-col cols="12" sm="4">
        <div class="d-flex justify-end align-center">
          <span class="fs-14px mr-2">{{
            $t("labels.sum_order", { count: items.length })
          }}</span>
          <v-btn
            color="success"
            @click="createOrders"
            :disabled="isDisabledBtnSubmit"
          >
            <v-icon>mdi-check</v-icon> {{ $t("labels.create_order") }}
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { read, utils } from "xlsx";
import { httpClient } from "@/libs/http";
import { E_MARKETS } from "@/libs/const";
import _ from "lodash";
import moment from "moment";

export default {
  name: "CreateEMarket",
  components: {
    // SelectEMarket: () => import("@/components/common/SelectEMarket.vue"),
    // SelectEMarketShop: () => import("@/components/common/SelectEMarketShop.vue"),
  },
  data: () => ({
    isLoading: false,
    page: 1,
    totalPage: 1,
    deliveryCompanyNames: [],
    items: [],
    errRows: [],
    errTexts: [],
    filters: {},
    file: null,
    idWarehouse: null,
    idEMarket: null,
    idEMarketShop: null,
    eMarketWarehouseName: null,
    auto_resolve_order: 0,
    eMarkets: [...E_MARKETS],
    shops: [],
  }),
  computed: {
    isDisabledBtnSubmit() {
      return (
        this.items.length === 0 || this.errRows.length > 0 || !this.idWarehouse
      );
    },
    sourceOptions() {
      if (!this.idWarehouse) {
        return [];
      }

      const warehouseEMarkets = [];
      const warehouseShops = [...this.shops].filter(
        (shop) => shop.id_warehouse == this.idWarehouse
      );

      for (let i = 0; i < warehouseShops.length; i++) {
        const shop = warehouseShops[i];
        if (!warehouseEMarkets.includes(shop.id_e_marketplace)) {
          warehouseEMarkets.push(shop.id_e_marketplace);
        }
      }

      const allowEMarkets = [...this.eMarkets].filter((em) =>
        warehouseEMarkets.includes(em.value)
      );
      return allowEMarkets;
    },
    shopOptions() {
      if (!this.idWarehouse || !this.idEMarket) {
        return [];
      }

      return [...this.shops]
        .filter(
          (shop) =>
            shop.id_e_marketplace == this.idEMarket &&
            shop.id_warehouse == this.idWarehouse
        )
        .map((shop) => ({
          text: shop.display_name,
          value: shop.id,
        }));
    },
  },
  mounted() {
    this.getShops();
    this.getDeliveryCompany();
  },
  methods: {
    async getShops() {
      const { data } = await httpClient.post("/list-e-market-shop", {
        active: 1,
      });
      this.shops = [...data];
    },
    onWarehouseChange(val) {
      this.idWarehouse = val;
    },
    onEMarketWarehouseChange(val) {
      this.eMarketWarehouseName = val;
    },
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value };
      this.items = [];
      this.errRows = [];
      this.errTexts = [];
    },
    readByGiuTon(values) {
      const mappingFields = {
        "Tên chương trình": "receiver_name",
        Barcode: "barcode",
        "Số lượng": "quantity",
      };
      const customer = window.me && window.me.customer;
      // const address = customer.address.split(',')
      let items = [...values].map((v, index) => {
        const item = {
          tt: index + 1,
          customer_order_id: this.generateRandomString(12),
          receiver_phone_number: customer.hotline,
          id_city: customer.id_city,
          id_county: customer.id_county,
          id_ward: customer.id_wards,
          address: customer.address,
          delivery_company: "TVC-GiuTon",
          delivery_order_id: "TVC",
          cash_on_delivery: 0,
          tvc: 1,
        };
        Object.keys(v).forEach((i) => {
          const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
          item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
        });

        item.goods_items = [
          {
            barcode: item.barcode,
            quantity: item.quantity,
          },
        ];
        return item;
      });
      items = this.updateDeliveryCompany(items);
      this.items = [...items];
      this.validateData(items);
    },
    readByShopee(values) {
      const customer = window.me && window.me.customer;
      const idCountry = customer.id_country;

      let mappingFields = {
        "Mã đơn hàng": "customer_order_id",
        "Tên Người nhận": "receiver_name",
        "Số điện thoại": "receiver_phone_number",
        "Tỉnh/Thành phố": "city_name",
        "TP / Quận / Huyện": "county_name",
        Quận: "ward_name",
        "Địa chỉ nhận hàng": "address",
        "Tổng số tiền người mua thanh toán": "cash_on_delivery",
        "Phương thức thanh toán": "payment_method",
        "Nhận xét từ Người mua": "note",
        "SKU phân loại hàng": "barcode",
        "Số lượng": "quantity",
        "Tổng giá bán (sản phẩm)": "bill_price",
        "Đơn Vị Vận Chuyển": "delivery_company",
        "Mã vận đơn": "delivery_order_id",
        "Phương thức giao hàng": "delivery_type",
        // "Warehouse name": "warehouse_name",
        "Tên kho hàng": "warehouse_name",
        "Ngày đặt hàng": "order_created_at",
        "Ngày giao hàng dự kiến": "deadline_for_lsr",
      };

      const phFields = {
        "Order ID": "customer_order_id",
        "Receiver Name": "receiver_name",
        "Phone Number": "receiver_phone_number",
        Province: "city_name",
        City: "county_name",
        District: "ward_name",
        "Delivery Address": "address",
        "Grand Total": "cash_on_delivery",
        "Phương thức thanh toán": "payment_method",
        Note: "note",
        "SKU Reference No.": "barcode",
        Quantity: "quantity",
        "Product Subtotal": "bill_price",
        "Shipping Option": "delivery_company",
        "Tracking Number*": "delivery_order_id",
        "Shipment Method": "delivery_type",
        "Warehouse name": "warehouse_name",
        "Order Creation Date": "order_created_at",
        "Estimated Ship Out Date": "deadline_for_lsr",
      };
      if (idCountry == 2) {
        mappingFields = { ...phFields };
      }

      if (this.eMarketWarehouseName) {
        values = [...values].filter(
          (val) =>
            val["Warehouse name"] == this.eMarketWarehouseName ||
            val["Tên kho hàng"] == this.eMarketWarehouseName
        );
      }
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });

        const checkIsValidCreatedAt = moment(
          item.order_created_at,
          "YYYY-MM-DD HH:mm",
          true
        ).isValid();
        item.order_created_at = checkIsValidCreatedAt
          ? item.order_created_at
          : moment(this.excelDateToJSDate(item.order_created_at)).format(
              "YYYY-MM-DD HH:mm:ss"
            );

        const checkIsValidDeadline = moment(
          item.deadline_for_lsr,
          "YYYY-MM-DD HH:mm",
          true
        ).isValid();
        item.deadline_for_lsr = checkIsValidDeadline
          ? item.deadline_for_lsr
          : moment(this.excelDateToJSDate(item.deadline_for_lsr)).format(
              "YYYY-MM-DD HH:mm:ss"
            );

        item.cash_on_delivery =
          item.payment_method &&
          item.payment_method === "Thanh toán khi nhận hàng"
            ? +item.cash_on_delivery
            : 0;
        item.delivery_company =
          item.delivery_type &&
          ["Mang hàng tới Bưu cục", "dropoff"].includes(item.delivery_type)
            ? `BC-${item.delivery_company}`
            : item.delivery_company;
        const goods_items = [
          {
            barcode: item.barcode,
            quantity: item.quantity,
            bill_price: item.bill_price,
          },
        ];
        item.goods_items = [...goods_items];
        item.tvc = 1;
        item.order_status = "PROCESSED";
        return item;
      });

      /*items.forEach(item => {
        const checkItem = data.find(d => d.customer_order_id == item.customer_order_id)
        if (checkItem) {
          const checkItemIndex = data.findIndex(d => d.customer_order_id == item.customer_order_id)
          checkItem.goods_items.push(...item.goods_items)
          data[checkItemIndex] = {...checkItem}
        } else {
          item.tt = tt
          data.push(item)
          tt++
        }
      })*/

      let data = [];
      let tt = 1;
      items.forEach((item) => {
        const checkItem = data.find(
          (d) => d.customer_order_id == item.customer_order_id
        );
        if (checkItem) {
          const checkItemIndex = data.findIndex(
            (d) => d.customer_order_id == item.customer_order_id
          );
          const checkItemGoods = checkItem.goods_items.filter(
            (g) => g.barcode == item.barcode
          );
          if (!checkItemGoods || checkItemGoods.length === 0) {
            checkItem.goods_items.push(...item.goods_items);
          } else {
            const checkItemGoodsIndex = checkItem.goods_items.findIndex(
              (g) => g.barcode == item.barcode
            );
            let quantity = +checkItem.goods_items[checkItemGoodsIndex].quantity;
            let bill_price =
              +checkItem.goods_items[checkItemGoodsIndex].bill_price;
            let barcode = checkItem.goods_items[checkItemGoodsIndex].barcode;
            quantity = quantity + +item.quantity;
            bill_price = bill_price + +item.bill_price;
            checkItem.goods_items[checkItemGoodsIndex] = {
              barcode,
              quantity,
              bill_price,
            };
          }
          data[checkItemIndex] = { ...checkItem };
        } else {
          item.tt = tt;
          data.push(item);
          tt++;
        }
      });

      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    readByLazada(values) {
      const mappingFields = {
        orderItemId: "order_item_id",
        orderNumber: "customer_order_id",
        shippingName: "receiver_name",
        shippingPhone: "shipping_phone",
        billingPhone: "billing_phone",
        shippingAddress3: "city_name",
        shippingAddress4: "county_name",
        shippingAddress5: "ward_name",
        shippingAddress: "address",
        paidPrice: "paid_price",
        unitPrice: "unit_price",
        shippingFee: "shipping_fee",
        sellerDiscountTotal: "seller_discount",
        payMethod: "payment_method",
        sellerSku: "barcode",
        shippingProviderFM: "delivery_company",
        shippingProvider: "shipping_provider",
        trackingCode: "delivery_order_id",
        createTime: "order_created_at",
        status: "order_status",
      };
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });
        item.receiver_phone_number = item.shipping_phone || item.billing_phone;
        const cod = +item.paid_price; //(Math.round(+item.unit_price) + Math.round(+item.shipping_fee) + Math.round(+item.seller_discount) || 0)
        item.cash_on_delivery =
          item.payment_method && item.payment_method.toLowerCase() === "cod"
            ? cod
            : 0;
        item.order_created_at = item.order_created_at
          ? moment(item.order_created_at, "DD MMM YYYY HH:mm").format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "";
        const goods_items = [
          {
            barcode: item.barcode,
            quantity: 1,
            bill_price: +item.unit_price,
          },
        ];
        item.goods_items = [...goods_items];
        item.tvc = 1;
        return item;
      });

      let data = [];
      let tt = 1;
      items.forEach((item) => {
        const checkItem = data.find(
          (d) => d.customer_order_id == item.customer_order_id
        );
        if (checkItem) {
          const checkItemIndex = data.findIndex(
            (d) => d.customer_order_id == item.customer_order_id
          );
          const checkItemGoods = checkItem.goods_items.find(
            (g) => g.barcode == item.barcode
          );
          if (checkItemGoods) {
            const checkItemGoodsIndex = checkItem.goods_items.findIndex(
              (g) => g.barcode == item.barcode
            );
            checkItem.goods_items[checkItemGoodsIndex].quantity++;
            checkItem.goods_items[checkItemGoodsIndex].bill_price +=
              +checkItemGoods.unit_price;
          } else {
            checkItem.goods_items.push(...item.goods_items);
          }
          checkItem.cash_on_delivery += item.cash_on_delivery || 0;
          data[checkItemIndex] = { ...checkItem };
        } else {
          item.tt = tt;
          data.push(item);
          tt++;
        }
      });

      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    readByTiktok(values) {
      const mappingFields = {
        "Order ID": "customer_order_id",
        Recipient: "receiver_name",
        "Phone #": "receiver_phone_number",
        Country: "city_name",
        County: "county_name",
        // 'Districts': 'ward_name',
        // 'Province': 'city_name',
        // 'District': 'county_name',
        // 'Quận': 'ward_name',
        "Detail Address": "address",
        "Order Amount": "cash_on_delivery",
        "Payment Method": "payment_method",
        "Seller SKU": "barcode",
        Quantity: "quantity",
        "SKU Subtotal After Discount": "bill_price",
        "Shipping Provider Name": "delivery_company",
        "Tracking ID": "delivery_order_id",
        "Package ID": "package_id",
        "Warehouse Name": "warehouse_name",
        "Created Time": "order_created_at",
      };
      /* console.log("this.eMarketWarehouseName", this.eMarketWarehouseName);
      if (this.eMarketWarehouseName) {
        console.log(values);
        values = [...values].filter(
          (val) => val["Warehouse Name"] == this.eMarketWarehouseName
        );
      } */
      const customer = (window.me && window.me.customer) || {};
      let items = [...values].map((v) => {
        const item = {};
        Object.keys(v).forEach((i) => {
          if (mappingFields[i]) {
            const val = this.replaceEmojiChar((`${v[i]}` || "").trim());
            item[mappingFields[i]] = Buffer.from(`${val}`, "utf-8").toString();
          }
        });
        let cod = item.cash_on_delivery
          ? item.cash_on_delivery.replace(/[^\d.-]+/g, "")
          : 0;
        if (customer.id_country == 1) {
          cod = `${cod}`.replace(/\./g, "");
        }
        item.cash_on_delivery =
          item.payment_method &&
          item.payment_method.toLowerCase() === "cash on delivery"
            ? +cod
            : 0;

        const momentFormat =
          customer.id_country == 1
            ? "DD/MM/YYYY HH:mm:ss"
            : "MM/DD/YYYY h:mm:ss A";
        item.order_created_at = item.order_created_at
          ? moment(item.order_created_at, momentFormat).format(
              "YYYY-MM-DD HH:mm:ss"
            )
          : "";
        let bill_price = item.bill_price
          ? item.bill_price.replace(/[^\d.-]+/g, "")
          : 0;
        if (customer.id_country == 1) {
          bill_price = `${bill_price}`.replace(/\./g, "");
        }
        const goods_items = [
          {
            barcode: item.barcode,
            quantity: item.quantity,
            bill_price: +bill_price,
          },
        ];
        item.goods_items = [...goods_items];
        item.tvc = 1;
        item.address = item.address || "";
        item.order_status = "AWAITING_COLLECTION";
        return item;
      });

      let data = [];
      let tt = 1;
      items.forEach((item, k) => {
        if (k > 0) {
          const checkItem = data.find(
            (d) => d.customer_order_id == item.customer_order_id
          );
          /*if (checkItem) {
            const checkItemIndex = data.findIndex(d => d.customer_order_id == item.customer_order_id)
            checkItem.goods_items.push(...item.goods_items)
            data[checkItemIndex] = {...checkItem}
          } else {
            item.tt = tt
            data.push(item)
            tt++
          }*/
          if (checkItem) {
            const checkItemIndex = data.findIndex(
              (d) => d.customer_order_id == item.customer_order_id
            );
            const checkItemGoods = checkItem.goods_items.filter(
              (g) => g.barcode == item.barcode
            );
            if (!checkItemGoods || checkItemGoods.length === 0) {
              checkItem.goods_items.push(...item.goods_items);
            } else {
              const checkItemGoodsIndex = checkItem.goods_items.findIndex(
                (g) => g.barcode == item.barcode
              );
              let quantity =
                +checkItem.goods_items[checkItemGoodsIndex].quantity;
              let bill_price =
                +checkItem.goods_items[checkItemGoodsIndex].bill_price;
              let barcode = checkItem.goods_items[checkItemGoodsIndex].barcode;
              quantity = quantity + +item.quantity;
              bill_price = bill_price + +item.bill_price;
              checkItem.goods_items[checkItemGoodsIndex] = {
                barcode,
                quantity,
                bill_price,
              };
            }
            data[checkItemIndex] = { ...checkItem };
          } else {
            item.tt = tt;
            data.push(item);
            tt++;
          }
        }
      });

      if (this.eMarketWarehouseName) {
        items = [...items].filter(
          (item) => item.warehouse_name == this.eMarketWarehouseName
        );
      }

      data = this.updateDeliveryCompany(data);
      this.items = [...data];
      this.validateData(data);
    },
    async onInputFileChange(e) {
      this.errRows = [];
      this.errTexts = [];
      const files = e.target.files;
      if (files && files[0]) {
        const f = files[0];
        this.file = f;
        const reader = new FileReader();
        reader.onload = async (e) => {
          const data = new Uint8Array(e.target.result);
          const workbook = read(data, { type: "array" });
          let sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          let values = [];
          switch (this.idEMarket) {
            case -1:
              values = utils.sheet_to_json(worksheet);
              this.readByGiuTon(values);
              break;
            case 1:
              values = utils.sheet_to_json(worksheet);
              this.readByShopee(values);
              break;
            case 2:
              values = utils.sheet_to_json(worksheet);
              this.readByLazada(values);
              break;
            case 5:
              values = utils.sheet_to_json(worksheet);
              this.readByTiktok(values);
              break;
          }
        };
        await reader.readAsArrayBuffer(f);
      }
    },

    updateDeliveryCompany(items) {
      items = [...items].map((item) => {
        if (item.delivery_company) {
          const checkDelivery = this.deliveryCompanyNames.find((d) =>
            [
              d.name.toLowerCase(),
              d.possible_name.toLowerCase(),
              d.short_name.toLowerCase(),
            ].includes(item.delivery_company.toLowerCase())
          );
          if (checkDelivery) {
            item.tvc = 1;
            // item.delivery_company = checkDelivery.name;
            item.id_delivery_company = checkDelivery.id_delivery_company;
          }
        }
        return item;
      });
      return items;
    },
    validateData(items) {
      const groupByCustomerOrderId = _.groupBy(items, "customer_order_id");
      Object.keys(groupByCustomerOrderId).forEach((k) => {
        if (groupByCustomerOrderId[k].length > 1) {
          const rows = groupByCustomerOrderId[k].map((g) => g.tt);
          this.errRows.push(...rows);
          this.errTexts.push(
            `<b>[${this.$t("labels.customer_order_id")}]</b>: "${
              k === "undefined" ? "" : k
            }" ${this.$t(
              "labels.duplicate"
            )} : <span class="error--text">${rows.join(", ")}</span>`
          );
        }
      });

      const requiredKeys = [
        "customer_order_id",
        "receiver_name",
        "receiver_phone_number",
        "goods_items",
        "delivery_company",
      ];
      switch (this.idEMarket) {
        case 1:
        case 2:
          requiredKeys.push("city_name");
          requiredKeys.push("county_name");
          requiredKeys.push("delivery_order_id");
          requiredKeys.push("address");
          break;
        case 5:
          break;
      }

      const requiredKeyTrans = {
        customer_order_id: this.$t("labels.customer_order_id"),
        receiver_name: this.$t("labels.receiver"),
        receiver_phone_number: this.$t("labels.phone_number"),
        city_name: this.$t("labels.city"),
        county_name: this.$t("labels.district"),
        ward_name: this.$t("labels.ward"),
        address: this.$t("labels.address"),
        goods_items: this.$t("labels.product"),
        delivery_company: this.$t("labels.delivery_company"),
        delivery_order_id: this.$t("labels.delivery_order_id"),
      };
      requiredKeys.forEach((i) => {
        const rows = items.filter((item) => !item[i] || item[i].length === 0);
        if (rows.length > 0) {
          const iRows = [...rows].map((r) => r.tt);
          this.errRows.push(...iRows);
          this.errTexts.push(
            `<b>[${requiredKeyTrans[i]}]</b>: ${this.$t(
              "labels.invalid_value"
            )}: <span class="error--text">${iRows.join(", ")}</span>`
          );
        }
      });
    },
    async createOrders() {
      if (this.isDisabledBtnSubmit) {
        return false;
      }
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        let fd = new FormData();
        fd.append("file", this.file);
        fd.append("orders", JSON.stringify(this.items));
        fd.append("create_method", 3);
        fd.append("id_e_market", this.idEMarket);
        fd.append("id_e_market_shop", this.idEMarketShop);
        fd.append("id_warehouse", this.idWarehouse);
        fd.append("auto_resolve_order", this.auto_resolve_order);
        await httpClient.post("/orders-create", fd);
        this.items = [];
        this.errRows = [];
        this.errTexts = [];
        this.isLoading = false;
        this.$vToastify.success(this.$t("messages.order_created_success"));
      } catch (e) {
        this.isLoading = false;
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        const validates = (e.response &&
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.validates) || { errRows: [], errTexts: [] };
        if (validates && validates.errRows && validates.errTexts) {
          const lang = localStorage.getItem("_lang") || "vi";
          this.errRows = [...validates.errRows];
          this.errTexts =
            lang == "vi" ? [...validates.errTexts] : [...validates.errEnTexts];
        }
        this.$vToastify.error(errMsg);
      }
    },
    async getDeliveryCompany() {
      const { data } = await httpClient.post("/get-delivery-company-names");
      this.deliveryCompanyNames = [...data];
    },
  },
};
</script>

<style scoped></style>
